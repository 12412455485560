import { Box, Button, MenuItem, Select, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  setDoc,
} from "firebase/firestore";
import React from "react";
import { firestore } from "../firebase";
import PropertyContext from "../contexts/PropertyContext";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { set } from "date-fns";

function DispatchNew() {
  const [loading, setLoading] = React.useState(true);
  const [employeeList, setEmployeeList] = React.useState([]);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [roomsList, setRoomsList] = React.useState([]);
  const [selectedEmployeeDispatch, setSelectedEmployeeDispatch] =
    React.useState([]);
  const [updatedTime, setUpdatedTime] = React.useState(null);

  const { selectedProperty } = React.useContext(PropertyContext);

  React.useEffect(() => {
    if (!selectedProperty) {
      return;
    }
    if (!selectedEmployee) {
      return;
    }

    setSelectedEmployeeDispatch([]);
  }, [selectedEmployee, selectedDate]);

  React.useEffect(() => {
    //fetch users
    const employeesRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "Staff"
    );
    const roomsRef = collection(
      firestore,
      "properties",
      selectedProperty,
      "units"
    );

    const queryEmployees = query(employeesRef);
    const queryRooms = query(roomsRef);

    const unsubscribeEmployees = onSnapshot(queryEmployees, (snapshot) => {
      const employees = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      console.log(employees);
      setEmployeeList(employees);
    });

    const unsubscribeRooms = onSnapshot(queryRooms, (snapshot) => {
      const rooms = [];
      snapshot.forEach((doc) => {
        rooms.push(doc.data());
      });
      setRoomsList(rooms);
    });

    setLoading(false);
  }, []);

  const employeeTypes = ["manager", "housekeeping", "maintenance", "frontDesk"];

  const returnRoomsByEmployeeType = () => {
    if (selectedEmployee?.userType === "housekeeping") {
      return roomsList.map(
        (room) =>
          `${room.displayName} - ${room.selectedHousekeepingRequirements}`
      );
    } else if (selectedEmployee?.userType === "maintenance") {
      return roomsList.map(
        (room) =>
          `${room.displayName} - ${room.selectedMaintenanceRequirements}`
      );
    } else {
      return roomsList.map((room) => room.displayName);
    }
  };

  const taskTypes = [
    {
      id: 1,
      task: "Porter",
      taskType: ["housekeeping", "maintenance"],
      taskLocations: ["lobby", "hallway", "grounds"],
    },
    {
      id: 2,
      task: "Room Turn",
      taskType: ["housekeeping", "maintenance"],
      taskLocations: returnRoomsByEmployeeType(),
    },
    //task 3 should only be available to maintenance, and location should be a dropdown of all building locations, or "all buildings"
    {
      id: 3,
      task: "Work Orders",
      taskType: ["maintenance"],
      taskLocations: [
        ...new Set([
          ...roomsList.map((room) => room.Building),
          "All Buildings",
        ]),
      ],
    },
    { id: 4, task: "Lunch", taskType: employeeTypes, taskLocations: ["lobby"] },
    { id: 5, task: "Break", taskType: employeeTypes, taskLocations: ["lobby"] },
    {
      id: 6,
      task: "Meeting",
      taskType: employeeTypes,
      taskLocations: ["lobby"],
    },
    {
      id: 7,
      task: "Training",
      taskType: employeeTypes,
      taskLocations: ["lobby"],
    },
    { id: 8, task: "Other", taskType: employeeTypes, taskLocations: ["lobby"] },
  ];

  const columns = [
    // starttime should be a time picker
    {
      field: "startTime",
      headerName: "Start Time",
      width: 150,
      editable: true,
      renderCell: (params) => {
        console.log(params.row);
        const timestamp = params.row?.startTime;
        const parsedTime = timestamp
          ? dayjs.unix(timestamp.seconds) // Convert Firestore timestamp to dayjs
          : null;

        console.log("Parsed Time:", parsedTime);
        return (
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={parsedTime}
                onAccept={(e) => {
                  console.log("onAccept", e);
                  params.row.startTime = e?.$d;
                  handleCellEditCommit({
                    id: params.id,
                    field: "startTime",
                    value: e?.$d,
                  });
                }}
              />
            </LocalizationProvider>
          </Box>
        );
      },
    },
    // endtime should be a time picker
    {
      field: "endTime",
      headerName: "End Time",
      width: 150,
      editable: true,
      renderCell: (params) => {
        console.log(params.row);
        const timestamp = params.row?.endTime;
        const parsedTime = timestamp
          ? dayjs.unix(timestamp.seconds) // Convert Firestore timestamp to dayjs
          : null;

        console.log("Parsed Time:", parsedTime);
        return (
          <Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <TimePicker
                value={parsedTime}
                onAccept={(e) => {
                  console.log("onAccept", e);
                  handleCellEditCommit({
                    id: params.id,
                    field: "endTime",
                    value: e?.$d,
                  });
                }}
              />
            </LocalizationProvider>
          </Box>
        );
      },
    },
    // task should be a dropdown
    {
      field: "task",
      headerName: "Task",
      width: 150,
      editable: true,
      renderCell: (params) => {
        // Ensure `selectedEmployee` is valid before accessing its properties
        if (!selectedEmployee) return null;

        console.log("Selected Employee:", selectedEmployee);
        console.log("Employee List:", employeeList);

        // Convert employee list to an object for fast lookup
        const employeesObj = Object.fromEntries(
          employeeList.map((e) => [e.id, e])
        );

        // Find the selected employee's type
        const employeeData = employeesObj[selectedEmployee]; // Get full employee object
        if (!employeeData) return null; // Prevent errors if employee is not found
        const employeeType = employeeData.userType;

        const filteredTasks = taskTypes.filter((task) =>
          task.taskType.includes(employeeType)
        ); // Filter valid tasks)

        return (
          <Box style={{ width: "100%" }}>
            <Select
              fullWidth
              value={params.row.task || ""}
              onChange={(e) => {
                console.log("Task updated:", e.target.value);
                // Update task field logic here
                handleCellEditCommit({
                  id: params.id,
                  field: "task",
                  value: e.target.value,
                });
              }}
            >
              {filteredTasks.map((task) => (
                <MenuItem key={task.task} value={task.task}>
                  {task.task}
                </MenuItem>
              ))}
            </Select>
          </Box>
        );
      },
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
      editable: true,

      renderCell: (params) => {
        //Should be a Select Dropdown of all taskLocations for the selected taskLocation, or empty if no taskType is selected
        console.log(params.row);
        if (!params.row.task) {
          return null;
        }

        const task = taskTypes.find((task) => task.task === params.row.task);
        if (!task) {
          return null;
        }
        console.log(task.taskLocations);

        return (
          <Box style={{ width: "100%" }}>
            <Select
              fullWidth
              value={params.row.location || ""}
              onChange={(e) => {
                //update the location
                console.log(params.row);
                console.log(params);
                handleCellEditCommit({
                  id: params.id,
                  field: "location",
                  value: e.target.value,
                });
              }}
            >
              {(task.taskLocations || []).map((location) => (
                <MenuItem key={location} value={location}>
                  {location}
                </MenuItem>
              ))}
            </Select>
          </Box>
        );
      },
    },
    { field: "notes", headerName: "Notes", width: 150 },
  ];

  React.useEffect(() => {
    if (!selectedEmployee || !selectedDate) {
      return;
    }

    // if selected date is not a valid date, return
    if (isNaN(selectedDate)) {
      return;
    }

    const getDispatches = async () => {
      //fetch dispatch items
      const formatDate = new Date(selectedDate).toISOString().split("T")[0]; // error control
      console.log(`getting dispatches for: `, selectedEmployee, formatDate);

      const dispatchDateRef = doc(
        firestore,
        "properties",
        selectedProperty,
        "dispatches",
        formatDate
      );
      const userDispatchDateRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "dispatches",
        formatDate,
        selectedEmployee
      );

      const dispatchDate = await getDoc(dispatchDateRef);
      if (!dispatchDate.exists()) {
        const init = {
          init: true,
        };
        //random number
        // const randomId = Math.floor(Math.random() * 1000000);
        // setSelectedEmployeeDispatch([
        //   {
        //     id: randomId, // Generate a unique ID
        //     startTime: "",
        //     endTime: "",
        //     task: "",
        //     location: "",
        //     notes: "",
        //     status: "pending",
        //     completed: false,
        //   },
        // ]);
        // console.log(
        //   "..................KKKKKKKKKKKKKKKKK>>>>>>>>>>>>>>>>>>>>>>>>>>>>.........."
        // );
        await setDoc(dispatchDateRef, init);
      }
      const q = query(userDispatchDateRef);
      const userDispatchDocs = await getDocs(q);

      if (userDispatchDocs.empty) {
        //generate a random number for the id
        // const randomId = Math.floor(Math.random() * 1000000);
        // setSelectedEmployeeDispatch([
        //   {
        //     id: randomId, // Generate a unique ID
        //     startTime: "",
        //     endTime: "",
        //     task: "",
        //     location: "",
        //     notes: "",
        //     status: "pending",
        //     completed: false,
        //   },
        // ]);
        initializeNewDispatchRow();
        return;
      } else {
        const dailyDispatch = userDispatchDocs.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setSelectedEmployeeDispatch(dailyDispatch);
      }
    };
    getDispatches();

    //if no dispatch for date, initialize first dispatch item
  }, [selectedEmployee, selectedDate, updatedTime]);

  const handleCellEditCommit = (params) => {
    console.log("handleCellEditCommit", params);
    // return;
    const { id, field, value } = params;

    // Update Firestore when an edit is made
    updateDispatchRecord(id, field, value);

    // Check if we should initialize a new row
    if (field === "endTime") {
      const row = selectedEmployeeDispatch.find((row) => row.id === id);
      if (row?.startTime && value) {
        initializeNewDispatchRow();
      }
    }
    setTimeout(() => {
      setUpdatedTime(new Date());
    }, 500);
    // setUpdatedTime(new Date());
  };

  const updateDispatchRecord = async (id, field, value) => {
    console.log("updateDispatchRecord", id, field, value);
    if (!selectedEmployee || !selectedDate) return;

    try {
      const formatDate = new Date(selectedDate).toISOString().split("T")[0];
      console.log(`Updating ${field} to ${value} for dispatch ${id}`);
      console.log(selectedProperty, formatDate, selectedEmployee, id);
      const dispatchDocRef = doc(
        firestore,
        "properties",
        selectedProperty,
        "dispatches",
        formatDate,
        selectedEmployee,
        id.toString()
      );

      await setDoc(dispatchDocRef, { [field]: value }, { merge: true });

      console.log(`Updated ${field} to ${value} for dispatch ${id}`);
    } catch (error) {
      console.error("Error updating Firestore:", error);
    }
  };
  const initializeNewDispatchRow = async () => {
    if (!selectedEmployee || !selectedDate) return;

    try {
      const formatDate = new Date(selectedDate).toISOString().split("T")[0];
      //generate uuid from uuid package

      const newDispatch = {
        startTime: "",
        endTime: "",
        task: "",
        location: "",
        notes: "",
        status: "pending",
        completed: false,
      };
      const dispatchDocRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "dispatches",
        formatDate,
        selectedEmployee
      );

      const createdDoc = await addDoc(dispatchDocRef, newDispatch);

      newDispatch.id = createdDoc.id;

      setSelectedEmployeeDispatch((prev) => [...prev, newDispatch]);

      console.log("Initialized new dispatch row");
    } catch (error) {
      console.error("Error initializing new row:", error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <div>
        <div>
          <Select
            value={selectedEmployee}
            onChange={(e) => {
              setSelectedEmployee(e.target.value);
            }}
          >
            {employeeList.map((employee) => (
              <MenuItem value={employee.id}>{employee.fullName}</MenuItem>
            ))}
          </Select>
        </div>
        <Box>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              //value={dayjs(selectedDate)}
              onChange={(newDate) => {
                setSelectedDate(newDate.$d.setUTCHours(6, 0, 0, 0));
                console.log(newDate.$d.setUTCHours(6, 0, 0, 0));
                //   getDispatches();
              }}
            />
          </LocalizationProvider>
        </Box>
      </div>
      <div>content</div>
      {/* only display if selectedEmployee and selectedDate are set */}
      {selectedDate && selectedEmployee && (
        <DataGrid
          rows={selectedEmployeeDispatch}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          checkboxSelection
          disableSelectionOnClick
          onCellEditCommit={() => console.log("onCellEditCommit")}
          onCellEditStop={(params) => console.log("onCellEditStop", params)}
          onEditCellChange={(params) => console.log("onEditCellChange", params)}
          onEditCellChangeCommitted={(params) =>
            console.log("onEditCellChangeCommitted", params)
          }
          processRowUpdate={(newRow) => {
            console.log("Row Updated:", newRow);
            // handleCellEditCommit(newRow);
            return newRow;
          }}
          onProcessRowUpdateError={(error) =>
            console.error("Row Update Error:", error)
          }
        />
      )}
      <Button
        onClick={() => {
          //log tasks
          console.log(selectedEmployeeDispatch);
        }}
      >
        <Typography>log tasks</Typography>
      </Button>
    </Box>
  );
}

export default DispatchNew;
