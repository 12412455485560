// // import React, { useContext, useEffect, useState } from "react";
// // import ExcelJS from "exceljs";
// // import { saveAs } from "file-saver";
// // import { collection, query, getDocs } from "firebase/firestore";
// // import { firestore } from "../firebase";

// // import PropertyContext from "../contexts/PropertyContext";

// // const GenerateDRList = () => {
// //   const [itemsByCategory, setItemsByCategory] = useState({});
// //   const [loading, setLoading] = useState(false);
// //   const { selectedProperty, rooms, items, itemCategories } =
// //     useContext(PropertyContext);

// //   const generateExcelDRL = async () => {
// //     console.log("generateDRL called");
// //     console.log(rooms);
// //     console.log("..................................");
// //     console.log(items);

// //     const workbook = new ExcelJS.Workbook();
// //     const mainWorksheet = workbook.addWorksheet("DRL Overview");

// //     // Create columns dynamically based on item names
// //     const itemColumns = items.map((item) => ({
// //       header: item.itemName,
// //       key: item.itemName,
// //       width: 20,
// //     }));

// //     const vendors = [
// //       { key: "vendorElectricalRepair", expectedCost: 500 },
// //       { key: "vendorPestControl", expectedCost: 350 },
// //       { key: "vendorPlumbingRepair", expectedCost: 200 },
// //       { key: "vendorSubfloor", expectedCost: 1200 },
// //       { key: "vendorTubResurface", expectedCost: 600 },
// //       { key: "vendorWindow", expectedCost: 500 },
// //     ];

// //     // Add the 'Room' column at the beginning
// //     mainWorksheet.columns = [
// //       { header: "Room", key: "room", width: 20 },
// //       ...itemColumns,
// //       ...vendors.map((vendor) => ({
// //         header: vendor.key.replace("vendor", "").trim(), // Format the header for readability
// //         key: vendor.key,
// //         width: 20,
// //       })),
// //       { header: "Total", key: "total", width: 20 }, // Add Total column
// //       { header: "Unit Notes", key: "unitNotes", width: 50 }, // Add Unit Notes column with a wider width
// //     ];

// //     mainWorksheet.getRow(1).font = { bold: true };
// //     mainWorksheet.getRow(1).alignment = { horizontal: "center" };
// //     mainWorksheet.getRow(1).fill = {
// //       type: "pattern",
// //       pattern: "solid",
// //       fgColor: { argb: "FFD3D3D3" },
// //     };

// //     // Filter rooms to only include those with status 'Down'
// //     const downRooms = rooms.filter((room) => room.status === "Down");

// //     // Populate the main worksheet with room data
// //     for (const room of downRooms) {
// //       // Create a new worksheet for each room
// //       const roomWorksheet = workbook.addWorksheet(room.displayName);
// //       const rowData = {
// //         room: {
// //           text: room.displayName,
// //           hyperlink: `#'${room.displayName}'!A1`,
// //         },
// //       };
// //       // Calculate the total cost for this row
// //       let totalCost = 0;

// //       items.forEach((item) => {
// //         rowData[item.itemName] = room[item.itemID] || ""; // Use item.itemID to check if it exists in the room object
// //       });

// //       items.forEach((item) => {
// //         const itemCountKey = item.itemID; // Key to check for the item count in the room object
// //         const itemCount = parseFloat(room[itemCountKey] || 0); // Get item count (default to 0)
// //         const itemCost = parseFloat(item.itemCost || 0); // Get the item's unit cost (default to 0)

// //         const itemTotalCost = itemCount * itemCost; // Calculate total cost for this item
// //         rowData[item.itemName] = itemCount > 0 ? itemCount : ""; // Display total cost if applicable

// //         totalCost += itemTotalCost; // Accumulate the total cost for the room
// //       });

// //       // Add vendor requirements and costs
// //       vendors.forEach((vendor) => {
// //         const vendorCount = parseInt(room[vendor.key] || 0, 10); // Get vendor count (default to 0)
// //         rowData[vendor.key] = vendorCount > 0 ? vendorCount : ""; // Display the count if greater than 0

// //         const vendorCost = vendorCount * vendor.expectedCost; // Calculate total cost for this vendor
// //         totalCost += vendorCost; // Add vendor cost to total cost
// //       });

// //       rowData.total = totalCost; // Add the total cost to the row

// //       rowData.unitNotes = room.unitNotes || ""; // Add unitNotes if present, otherwise leave blank

// //       mainWorksheet.addRow(rowData);

// //       // Add columns to the room worksheet
// //       roomWorksheet.columns = [
// //         { header: "Room", key: "room", width: 20 },
// //         ...itemColumns,
// //       ];
// //       roomWorksheet.getRow(1).font = { bold: true };
// //       roomWorksheet.getRow(1).alignment = { horizontal: "center" };
// //       roomWorksheet.getRow(1).fill = {
// //         type: "pattern",
// //         pattern: "solid",
// //         fgColor: { argb: "FFD3D3D3" },
// //       };

// //       // Add room data to the room worksheet
// //       const roomRowData = { room: room.displayName };
// //       items.forEach((item) => {
// //         roomRowData[item.itemName] = room[item.itemID] || "";
// //       });
// //       roomWorksheet.addRow(roomRowData);

// //       // Add image links to the room worksheet
// //       const imagesRef = collection(
// //         firestore,
// //         "properties",
// //         selectedProperty,
// //         "units",
// //         room.id,
// //         "images"
// //       );

// //       // original code to just inject the image URL as a hyperlink
// //       const imagesQuerySnapshot = await getDocs(query(imagesRef));
// //       let imageRow = 3;
// //       for (const doc of imagesQuerySnapshot.docs) {
// //         const image = doc.data();
// //         roomWorksheet.addRow([
// //           `Uploaded at: ${image.uploadedAt}`,
// //           `Link: ${image.url}`,
// //         ]);
// //         const cell = roomWorksheet.getCell(`B${imageRow}`);
// //         cell.value = {
// //           text: "View Image",
// //           hyperlink: image.url,
// //         };
// //         cell.font = { color: { argb: "FF0000FF" }, underline: true };
// //         imageRow += 1;
// //       }

// //       console.log("getting images");
// //       // code to inject image as image
// //       // const imagesQuerySnapshot = await getDocs(query(imagesRef));
// //       // let imageRow = 3;
// //       // for (const doc of imagesQuerySnapshot.docs) {
// //       //   const image = doc.data();
// //       //   roomWorksheet.addRow([
// //       //     `Uploaded at: ${image.uploadedAt}`,
// //       //     `Link: ${image.url}`,
// //       //   ]);

// //       //   // Load image from URL
// //       //   const response = await fetch(image.url);
// //       //   const arrayBuffer = await response.arrayBuffer();
// //       //   const base64String = arrayBufferToBase64(arrayBuffer);

// //       //   // Determine the extension of the image
// //       //   const extension = image.url.split(".").pop().toLowerCase();

// //       //   // Add image to workbook
// //       //   const imageId = roomWorksheet.workbook.addImage({
// //       //     base64: base64String,
// //       //     extension: extension,
// //       //   });

// //       //   // Add image to cell
// //       //   roomWorksheet.addImage(imageId, {
// //       //     tl: { col: 1, row: imageRow - 1 },
// //       //     ext: { width: 100, height: 100 }, // Adjust the size as needed
// //       //   });

// //       //   imageRow += 1;
// //       //   console.log(`imaged added for room ${room.id}`);
// //       // }

// //       // Helper function to convert ArrayBuffer to base64
// //       function arrayBufferToBase64(buffer) {
// //         console.log("arrayBufferToBase64 Called");
// //         let binary = "";
// //         const bytes = new Uint8Array(buffer);
// //         const len = bytes.byteLength;
// //         for (let i = 0; i < len; i++) {
// //           binary += String.fromCharCode(bytes[i]);
// //         }
// //         return window.btoa(binary);
// //       }
// //     }

// //     mainWorksheet.eachRow((row) => {
// //       row.eachCell((cell) => {
// //         cell.border = {
// //           top: { style: "thin" },
// //           left: { style: "thin" },
// //           bottom: { style: "thin" },
// //           right: { style: "thin" },
// //         };
// //       });
// //     });

// //     console.log("got here");
// //     const buffer = await workbook.xlsx.writeBuffer();
// //     const blob = new Blob([buffer], {
// //       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
// //     });
// //     console.log("got here 2");
// //     const today = new Date();
// //     const date = today.toISOString().split("T")[0];
// //     const dateStr = date.replace(/-/g, "_");
// //     //saving the file
// //     console.log("got here 3");
// //     saveAs(blob, `Generated_DRL_${dateStr}.xlsx`);
// //   };

// //   const groupItemsByType = (items) => {
// //     console.log("groupItemsByType called");
// //     return items.reduce((acc, item) => {
// //       if (!acc[item.itemType]) {
// //         acc[item.itemType] = [];
// //       }
// //       acc[item.itemType].push(item);
// //       return acc;
// //     }, {});
// //   };

// //   useEffect(() => {
// //     setLoading(true);
// //     const groupedItems = groupItemsByType(items);

// //     const newItemType = "Vendor Needed";
// //     if (!groupedItems[newItemType]) {
// //       groupedItems[newItemType] = [];
// //     }

// //     const vendorsList = [
// //       { itemID: "vendorElectricalRepair", itemName: "Electrical Repair" },
// //       { itemID: "vendorPestControl", itemName: "Pest Control" },
// //       { itemID: "vendorPlumbingRepair", itemName: "Plumbing Repair" },
// //       { itemID: "vendorTubResurface", itemName: "Tub Resurface" },
// //       { itemID: "vendorWindow", itemName: "Window" },
// //     ];

// //     vendorsList.forEach((item) => {
// //       groupedItems[newItemType].push({
// //         ...item,
// //         itemType: newItemType,
// //       });
// //     });

// //     setItemsByCategory(groupedItems);

// //     setLoading(false);
// //   }, [selectedProperty]);

// //   return (
// //     <div>
// //       <button onClick={generateExcelDRL} disabled={loading}>
// //         {loading ? "Generating..." : "Generate DRL"}
// //       </button>
// //     </div>
// //   );
// // };

// // export default GenerateDRList;

// import React, { useContext, useEffect, useState } from "react";
// import ExcelJS from "exceljs";
// import { saveAs } from "file-saver";
// import { collection, query, getDocs } from "firebase/firestore";
// import { firestore } from "../firebase";

// import PropertyContext from "../contexts/PropertyContext";

// const GenerateDRList = () => {
//   const [itemsByCategory, setItemsByCategory] = useState({});
//   const [loading, setLoading] = useState(false);
//   const { selectedProperty, rooms, items, itemCategories } =
//     useContext(PropertyContext);

//   // Helper functions for conversion using mapping objects
//   const convertHousekeeping = (req) => {
//     const housekeepingMapping = {
//       Refresh: 15,
//       Easy: 45,
//       Medium: 90,
//       Heavy: 120,
//       "Very Heavy": 180,
//       Completed: 0,
//     };
//     return housekeepingMapping[req] ?? 0;
//   };

//   const convertMaintenance = (req) => {
//     const maintenanceMapping = {
//       Easy: 30,
//       Medium: 60,
//       Heavy: 90,
//       "Very Heavy": 120,
//       Completed: 0,
//     };

//     return maintenanceMapping[req] ?? 0;
//   };

//   // Helper function to format minutes into "H:MM" format
//   const formatMinutesToHours = (totalMinutes) => {
//     const hours = Math.floor(totalMinutes / 60);
//     const minutes = totalMinutes % 60;
//     return `${hours}:${minutes.toString().padStart(2, "0")}`;
//   };

//   const generateExcelDRL = async () => {
//     console.log("generateDRL called");
//     console.log(rooms);
//     console.log("..................................");
//     console.log(items);

//     const workbook = new ExcelJS.Workbook();
//     const mainWorksheet = workbook.addWorksheet("DRL Overview");

//     // Create columns dynamically based on item names
//     const itemColumns = items.map((item) => ({
//       header: item.itemName,
//       key: item.itemName,
//       width: 20,
//     }));

//     const vendors = [
//       { key: "vendorElectricalRepair", expectedCost: 500 },
//       { key: "vendorPestControl", expectedCost: 350 },
//       { key: "vendorPlumbingRepair", expectedCost: 200 },
//       { key: "vendorSubfloor", expectedCost: 1200 },
//       { key: "vendorTubResurface", expectedCost: 600 },
//       { key: "vendorWindow", expectedCost: 500 },
//     ];

//     // Updated columns: added Housekeeping and Maintenance Requirements columns after Room
//     mainWorksheet.columns = [
//       { header: "Room", key: "room", width: 20 },
//       {
//         header: "Housekeeping Requirements",
//         key: "selectedHousekeepingRequirements",
//         width: 30,
//       },
//       {
//         header: "Maintenance Requirements",
//         key: "selectedMaintenanceRequirements",
//         width: 30,
//       },
//       ...itemColumns,
//       ...vendors.map((vendor) => ({
//         header: vendor.key.replace("vendor", "").trim(), // Format the header for readability
//         key: vendor.key,
//         width: 20,
//       })),
//       { header: "Total", key: "total", width: 20 },
//       { header: "Unit Notes", key: "unitNotes", width: 50 },
//     ];

//     mainWorksheet.getRow(1).font = { bold: true };
//     mainWorksheet.getRow(1).alignment = { horizontal: "center" };
//     mainWorksheet.getRow(1).fill = {
//       type: "pattern",
//       pattern: "solid",
//       fgColor: { argb: "FFD3D3D3" },
//     };

//     // Filter rooms to only include those with status 'Down'
//     const downRooms = rooms.filter((room) => room.status === "Down");

//     // Populate the main worksheet with room data
//     for (const room of downRooms) {
//       // Create a new worksheet for each room
//       const roomWorksheet = workbook.addWorksheet(room.displayName);
//       // Convert text values to numbers using the helper functions
//       const rowData = {
//         room: {
//           text: room.displayName,
//           hyperlink: `#'${room.displayName}'!A1`,
//         },
//         selectedHousekeepingRequirements: convertHousekeeping(
//           room.selectedHousekeepingRequirements
//         ),
//         selectedMaintenanceRequirements: convertMaintenance(
//           room.selectedMaintenanceRequirements
//         ),
//       };
//       console.log(rowData);

//       // Calculate the total cost for this row
//       let totalCost = 0;

//       // Populate item columns (display text value if any)
//       items.forEach((item) => {
//         rowData[item.itemName] = room[item.itemID] || "";
//       });

//       // Process items to calculate cost and add difficulty to requirements totals
//       items.forEach((item) => {
//         const itemCountKey = item.itemID; // Key to check for the item count in the room object
//         const itemCount = parseFloat(room[itemCountKey] || 0); // Get item count (default to 0)
//         const itemCost = parseFloat(item.itemCost || 0); // Get the item's unit cost (default to 0)
//         const itemDifficulty = parseFloat(item.itemDifficulty || 0); // Get the item's difficulty (default to 0)

//         const itemTotalCost = itemCount * itemCost; // Calculate total cost for this item
//         rowData[item.itemName] = itemCount > 0 ? itemCount : ""; // Display item count if applicable
//         totalCost += itemTotalCost; // Accumulate the total cost for the room

//         // If the item has a responsibility and a positive count, add its difficulty value to the appropriate requirement
//         if (itemCount > 0) {
//           if (item.itemResponsibility === "Maintenance") {
//             rowData.selectedMaintenanceRequirements +=
//               itemCount * itemDifficulty;
//           } else if (item.itemResponsibility === "Housekeeping") {
//             rowData.selectedHousekeepingRequirements +=
//               itemCount * itemDifficulty;
//           }
//         }
//       });

//       // Add vendor requirements and costs
//       vendors.forEach((vendor) => {
//         const vendorCount = parseInt(room[vendor.key] || 0, 10); // Get vendor count (default to 0)
//         rowData[vendor.key] = vendorCount > 0 ? vendorCount : ""; // Display the count if greater than 0

//         const vendorCost = vendorCount * vendor.expectedCost; // Calculate total cost for this vendor
//         totalCost += vendorCost; // Add vendor cost to total cost
//       });

//       // Update the rowData with the formatted hours:minutes values
//       rowData.selectedHousekeepingRequirements =
//         formatMinutesToHours(housekeepingTotal);
//       rowData.selectedMaintenanceRequirements =
//         formatMinutesToHours(maintenanceTotal);

//       rowData.total = totalCost; // Add the total cost to the row
//       rowData.unitNotes = room.unitNotes || ""; // Add unitNotes if present, otherwise leave blank

//       mainWorksheet.addRow(rowData);

//       // Add columns to the room worksheet
//       roomWorksheet.columns = [
//         { header: "Room", key: "room", width: 20 },
//         ...itemColumns,
//       ];
//       roomWorksheet.getRow(1).font = { bold: true };
//       roomWorksheet.getRow(1).alignment = { horizontal: "center" };
//       roomWorksheet.getRow(1).fill = {
//         type: "pattern",
//         pattern: "solid",
//         fgColor: { argb: "FFD3D3D3" },
//       };

//       // Add room data to the room worksheet
//       const roomRowData = { room: room.displayName };
//       items.forEach((item) => {
//         roomRowData[item.itemName] = room[item.itemID] || "";
//       });
//       roomWorksheet.addRow(roomRowData);

//       // Add image links to the room worksheet
//       const imagesRef = collection(
//         firestore,
//         "properties",
//         selectedProperty,
//         "units",
//         room.id,
//         "images"
//       );

//       // Original code to inject the image URL as a hyperlink
//       const imagesQuerySnapshot = await getDocs(query(imagesRef));
//       let imageRow = 3;
//       for (const doc of imagesQuerySnapshot.docs) {
//         const image = doc.data();
//         roomWorksheet.addRow([
//           `Uploaded at: ${image.uploadedAt}`,
//           `Link: ${image.url}`,
//         ]);
//         const cell = roomWorksheet.getCell(`B${imageRow}`);
//         cell.value = {
//           text: "View Image",
//           hyperlink: image.url,
//         };
//         cell.font = { color: { argb: "FF0000FF" }, underline: true };
//         imageRow += 1;
//       }

//       function arrayBufferToBase64(buffer) {
//         console.log("arrayBufferToBase64 Called");
//         let binary = "";
//         const bytes = new Uint8Array(buffer);
//         const len = bytes.byteLength;
//         for (let i = 0; i < len; i++) {
//           binary += String.fromCharCode(bytes[i]);
//         }
//         return window.btoa(binary);
//       }
//     }

//     mainWorksheet.getColumn("total").numFmt = "$#,##0.00";

//     mainWorksheet.eachRow((row) => {
//       row.eachCell((cell) => {
//         cell.border = {
//           top: { style: "thin" },
//           left: { style: "thin" },
//           bottom: { style: "thin" },
//           right: { style: "thin" },
//         };
//       });
//     });

//     console.log("got here");
//     const buffer = await workbook.xlsx.writeBuffer();
//     const blob = new Blob([buffer], {
//       type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
//     });
//     console.log("got here 2");
//     const today = new Date();
//     const date = today.toISOString().split("T")[0];
//     const dateStr = date.replace(/-/g, "_");
//     // Saving the file
//     console.log("got here 3");
//     saveAs(blob, `Generated_DRL_${dateStr}.xlsx`);
//   };

//   const groupItemsByType = (items) => {
//     console.log("groupItemsByType called");
//     return items.reduce((acc, item) => {
//       if (!acc[item.itemType]) {
//         acc[item.itemType] = [];
//       }
//       acc[item.itemType].push(item);
//       return acc;
//     }, {});
//   };

//   useEffect(() => {
//     setLoading(true);
//     const groupedItems = groupItemsByType(items);

//     const newItemType = "Vendor Needed";
//     if (!groupedItems[newItemType]) {
//       groupedItems[newItemType] = [];
//     }

//     const vendorsList = [
//       { itemID: "vendorElectricalRepair", itemName: "Electrical Repair" },
//       { itemID: "vendorPestControl", itemName: "Pest Control" },
//       { itemID: "vendorPlumbingRepair", itemName: "Plumbing Repair" },
//       { itemID: "vendorTubResurface", itemName: "Tub Resurface" },
//       { itemID: "vendorWindow", itemName: "Window" },
//     ];

//     vendorsList.forEach((item) => {
//       groupedItems[newItemType].push({
//         ...item,
//         itemType: newItemType,
//       });
//     });

//     setItemsByCategory(groupedItems);
//     setLoading(false);
//   }, [selectedProperty]);

//   return (
//     <div>
//       <button onClick={generateExcelDRL} disabled={loading}>
//         {loading ? "Generating..." : "Generate DRL"}
//       </button>
//     </div>
//   );
// };

// export default GenerateDRList;
import React, { useContext, useEffect, useState } from "react";
import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { collection, query, getDocs } from "firebase/firestore";
import { firestore } from "../firebase";

import PropertyContext from "../contexts/PropertyContext";

// Helper function to get Excel column letter given a column number
const getExcelColumnLetter = (colNum) => {
  let temp,
    letter = "";
  while (colNum > 0) {
    temp = (colNum - 1) % 26;
    letter = String.fromCharCode(temp + 65) + letter;
    colNum = (colNum - temp - 1) / 26;
  }
  return letter;
};

const GenerateDRList = () => {
  const [itemsByCategory, setItemsByCategory] = useState({});
  const [loading, setLoading] = useState(false);
  const { selectedProperty, rooms, items, itemCategories } =
    useContext(PropertyContext);

  // Helper functions for conversion using mapping objects
  const convertHousekeeping = (req) => {
    const housekeepingMapping = {
      Refresh: 15,
      Easy: 45,
      Medium: 90,
      Heavy: 120,
      "Very Heavy": 180,
      Completed: 0,
    };
    return housekeepingMapping[req] ?? 0;
  };

  const convertMaintenance = (req) => {
    const maintenanceMapping = {
      Easy: 30,
      Medium: 60,
      Heavy: 90,
      "Very Heavy": 120,
      Completed: 0,
    };
    return maintenanceMapping[req] ?? 0;
  };

  const convertColorNameToARGB = (colorName) => {
    const colorMapping = {
      blue: "FF0000FF",
      yellow: "FFFFFF00",
      red: "FFFF0000",
    };
    return colorMapping[colorName] || "FFFFFFFF";
  };

  // Helper function to format minutes into "H:MM" format
  const formatMinutesToHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}:${minutes.toString().padStart(2, "0")}`;
  };

  const generateExcelDRL = async () => {
    console.log("generateDRL called");
    console.log(rooms);
    console.log("..................................");
    console.log(items);

    const workbook = new ExcelJS.Workbook();
    const mainWorksheet = workbook.addWorksheet("DRL Overview");

    // Create columns dynamically based on item names
    const itemColumns = items.map((item) => ({
      header: item.itemName,
      key: item.itemName,
      width: 20,
    }));

    const vendors = [
      { key: "vendorElectricalRepair", expectedCost: 500, color: "yellow" },
      { key: "vendorPestControl", expectedCost: 350, color: "blue" },
      { key: "vendorPlumbingRepair", expectedCost: 200, color: "red" },
      { key: "vendorSubfloor", expectedCost: 1200, color: "red" },
      { key: "vendorTubResurface", expectedCost: 600, color: "red" },
      { key: "vendorWindow", expectedCost: 500, color: "red" },
    ];

    // Define main worksheet columns including the new requirements columns
    mainWorksheet.columns = [
      { header: "Room", key: "room", width: 20 },
      {
        header: "Housekeeping",
        key: "selectedHousekeepingRequirements",
        width: 30,
      },
      {
        header: "Maintenance",
        key: "selectedMaintenanceRequirements",
        width: 30,
      },
      ...itemColumns,
      ...vendors.map((vendor) => ({
        header: vendor.key.replace("vendor", "").trim(),
        key: vendor.key,
        width: 20,
      })),
      { header: "Total", key: "total", width: 20 },
      { header: "Unit Notes", key: "unitNotes", width: 50 },
    ];

    mainWorksheet.getRow(1).font = { bold: true };
    mainWorksheet.getRow(1).alignment = { horizontal: "center" };
    mainWorksheet.getRow(1).fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FFD3D3D3" },
    };

    // Filter rooms to only include those with status 'Down'
    const downRooms = rooms.filter((room) => room.status === "Down");

    // Collect row data in an array so we can sort before adding to the worksheet
    let rowDatas = [];

    for (const room of downRooms) {
      // (Optional) Create a worksheet for each room if needed
      const roomWorksheet = workbook.addWorksheet(room.displayName);

      // Start with base requirements values (in minutes)
      let housekeepingTotal = convertHousekeeping(
        room.selectedHousekeepingRequirements
      );
      let maintenanceTotal = convertMaintenance(
        room.selectedMaintenanceRequirements
      );

      // Build rowData object; note the 'room' cell includes a hyperlink
      let rowData = {
        room: {
          text: room.displayName,
          hyperlink: `#'${room.displayName}'!A1`,
        },
        selectedHousekeepingRequirements: housekeepingTotal, // to be formatted later
        selectedMaintenanceRequirements: maintenanceTotal, // to be formatted later
      };

      let totalCost = 0;

      // Process item columns and add difficulty adjustments
      items.forEach((item) => {
        const itemCountKey = item.itemID;
        const itemCount = parseFloat(room[itemCountKey] || 0);
        const itemCost = parseFloat(item.itemCost || 0);
        const itemDifficulty = parseFloat(item.itemDifficulty || 0);
        rowData[item.itemName] = itemCount > 0 ? itemCount : "";
        totalCost += itemCount * itemCost;
        if (itemCount > 0) {
          if (item.itemResponsibility === "Maintenance") {
            maintenanceTotal += itemCount * itemDifficulty;
            // console.log(
            //   `adding ${itemCount * itemDifficulty} to maintenance for ${
            //     item.itemName
            //   }`
            // );
          } else if (item.itemResponsibility === "Housekeeping") {
            housekeepingTotal += itemCount * itemDifficulty;
            console.log(
              `adding ${itemCount * itemDifficulty} to housekeeping for ${
                item.itemName
              }`
            );
          }
        }
      });

      // Format the requirements values as hours:minutes
      rowData.selectedHousekeepingRequirements =
        formatMinutesToHours(housekeepingTotal);
      rowData.selectedMaintenanceRequirements =
        formatMinutesToHours(maintenanceTotal);

      // Process vendor columns
      vendors.forEach((vendor) => {
        const vendorCount = parseInt(room[vendor.key] || 0, 10);
        rowData[vendor.key] = vendorCount > 0 ? vendorCount : "";
        totalCost += vendorCount * vendor.expectedCost;
      });

      totalCost += 15 * (housekeepingTotal / 60);
      totalCost += 25 * (maintenanceTotal / 60);

      rowData.total = totalCost; // Keep as number for currency formatting
      rowData.unitNotes = room.unitNotes || "";

      // Determine the vendor color for the room:
      // Priority: Pest Control (blue), then Electrical (yellow), then any other vendor (red).
      let vendorColor = "";
      if (parseInt(room["vendorPestControl"] || 0, 10) > 0) {
        vendorColor = "blue";
      } else if (parseInt(room["vendorElectricalRepair"] || 0, 10) > 0) {
        vendorColor = "yellow";
      } else {
        const otherVendors = [
          "vendorPlumbingRepair",
          "vendorSubfloor",
          "vendorTubResurface",
          "vendorWindow",
        ];
        for (let key of otherVendors) {
          if (parseInt(room[key] || 0, 10) > 0) {
            vendorColor = "red";
            break;
          }
        }
      }
      rowData.vendorColor = vendorColor; // Save the color in the row data

      // Push the row data into our array
      rowDatas.push(rowData);

      // Add columns to the room worksheet
      roomWorksheet.columns = [
        { header: "Room", key: "room", width: 20 },
        ...itemColumns,
      ];
      roomWorksheet.getRow(1).font = { bold: true };
      roomWorksheet.getRow(1).alignment = { horizontal: "center" };
      roomWorksheet.getRow(1).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFD3D3D3" },
      };

      // Add room data to the room worksheet
      const roomRowData = { room: room.displayName };
      items.forEach((item) => {
        roomRowData[item.itemName] = room[item.itemID] || "";
      });
      roomWorksheet.addRow(roomRowData);

      // Add image links to the room worksheet
      const imagesRef = collection(
        firestore,
        "properties",
        selectedProperty,
        "units",
        room.id,
        "images"
      );

      // Original code to inject the image URL as a hyperlink
      const imagesQuerySnapshot = await getDocs(query(imagesRef));
      let imageRow = 3;
      for (const doc of imagesQuerySnapshot.docs) {
        const image = doc.data();
        roomWorksheet.addRow([
          `Uploaded at: ${image.uploadedAt}`,
          `Link: ${image.url}`,
        ]);
        const cell = roomWorksheet.getCell(`B${imageRow}`);
        cell.value = {
          text: "View Image",
          hyperlink: image.url,
        };
        cell.font = { color: { argb: "FF0000FF" }, underline: true };
        imageRow += 1;
      }

      function arrayBufferToBase64(buffer) {
        console.log("arrayBufferToBase64 Called");
        let binary = "";
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;
        for (let i = 0; i < len; i++) {
          binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
      }
    }

    // Sort the row data by total cost (lowest to highest)
    rowDatas.sort((a, b) => a.total - b.total);

    // Add sorted rows to the main worksheet and apply cell fill for the "Room" column
    rowDatas.forEach((data) => {
      const newRow = mainWorksheet.addRow(data);
      if (data.vendorColor) {
        newRow.getCell("room").fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: convertColorNameToARGB(data.vendorColor) },
        };
      }
    });

    // Format the "Total" column as USD currency
    mainWorksheet.getColumn("total").numFmt = "$#,##0.00";

    // Determine the table range based on the number of rows and columns
    const totalRows = mainWorksheet.rowCount; // includes header
    const totalCols = mainWorksheet.columns.length;
    const lastColLetter = getExcelColumnLetter(totalCols);
    const tableRange = `A1:${lastColLetter}${totalRows}`;

    // Create an Excel table to wrap the data
    mainWorksheet.addTable({
      name: "DRLTable",
      ref: "A1",
      headerRow: true,
      totalsRow: false,
      style: {
        theme: "TableStyleMedium9",
        showRowStripes: true,
      },
      // Define table columns based on the worksheet headers
      columns: mainWorksheet.columns.map((col) => ({ name: col.header })),
      // Provide table rows as an array of arrays.
      // For the "Room" column, extract the text if a hyperlink object exists.
      rows: rowDatas.map((data) =>
        mainWorksheet.columns.map((col) => {
          const value = data[col.key];
          return value && typeof value === "object" && value.text
            ? value.text
            : value;
        })
      ),
    });

    // (Optional) Apply borders to all cells
    mainWorksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
    });

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const today = new Date();
    const date = today.toISOString().split("T")[0];
    const dateStr = date.replace(/-/g, "_");
    saveAs(blob, `Generated_DRL_${dateStr}.xlsx`);
  };

  const groupItemsByType = (items) => {
    return items.reduce((acc, item) => {
      if (!acc[item.itemType]) {
        acc[item.itemType] = [];
      }
      acc[item.itemType].push(item);
      return acc;
    }, {});
  };

  useEffect(() => {
    setLoading(true);
    const groupedItems = groupItemsByType(items);

    const newItemType = "Vendor Needed";
    if (!groupedItems[newItemType]) {
      groupedItems[newItemType] = [];
    }

    const vendorsList = [
      { itemID: "vendorElectricalRepair", itemName: "Electrical Repair" },
      { itemID: "vendorPestControl", itemName: "Pest Control" },
      { itemID: "vendorPlumbingRepair", itemName: "Plumbing Repair" },
      { itemID: "vendorTubResurface", itemName: "Tub Resurface" },
      { itemID: "vendorWindow", itemName: "Window" },
    ];

    vendorsList.forEach((item) => {
      groupedItems[newItemType].push({
        ...item,
        itemType: newItemType,
      });
    });

    setItemsByCategory(groupedItems);
    setLoading(false);
  }, [selectedProperty]);

  return (
    <div>
      <button onClick={generateExcelDRL} disabled={loading}>
        {loading ? "Generating..." : "Generate DRL"}
      </button>
    </div>
  );
};

export default GenerateDRList;
